import httpClient from '@/utils/http-client'

const resouce = 'efficient-sale'
const efficentSaleApi = {
  async getOpportunity() {
    const res = await httpClient({
      url: `${resouce}/oportunity`,
      method: 'GET',
    })
    return res.data
  },
  async getOpportunityByCompany(company) {
    const res = await httpClient({
      url: `${resouce}/oportunity/company`,
      method: 'POST',
      data: {
        company
      }
    })
    return res.data
  },

  async setCostingIdInOpp(oppId, iterationId) {
    const res = await httpClient({
      url: `${resouce}/oportunity/costingId`,
      method: 'POST',
      data: {
        id: oppId,
        iterationId
      }
    })
    return res.data
  },
}

export default efficentSaleApi
