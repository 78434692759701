import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Add } from '@mui/icons-material'
import { useAsyncRequest } from '@/utils/helper'
import TableAction from '@/components/common/TableAction'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TableHeadSortable, {
  getComparator,
} from '@/components/common/TableHeadSort'
import { TextField } from '@mui/material'

import folderApi from '../../api/folder'

function TaceList() {
  const TaceFolderID = process.env.REACT_APP_TACE_FOLDER_ID || '1feUEwZUbwt9bmF_Bky4OoGymjjLV-Svr'
  const [files, setFiles] = useState([])
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [searchName, setSearchName] = useState('')

  const { actionWrapper } = useAsyncRequest()
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = [
    {
      id: 'name',
      label: 'TACE filename',
    },
    {
      id: 'properties.reportFolderId',
      label: 'Folder selected',
    },
    {
      id: 'properties.startDate',
      label: 'Start Date',
    },
    {
      id: 'properties.endDate',
      label: 'End Date',
    },
    {
      id: 'createdTime',
      label: 'Created At',
    },
    {
      id: 'empty',
      diabledSort: true,
    },
  ]
  async function fetchFiles() {
    const res = await actionWrapper(
      () => folderApi.listFiles(TaceFolderID),
      {},
      true
    )
    setFiles(res.files)
  }

  async function handleDeleteFolder(folder) {
    await actionWrapper(() => folderApi.deleteFile(folder.id), {
      showMessage: true,
      isConfirm: true,
      refreshData: fetchFiles,
      description:
        'The tace file selected will be deleted, Please check before confirming',
    })
  }

  useEffect(() => {
    actionWrapper(() => fetchFiles())
  }, [])

  return (
    <div>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <div className="text-primary font-bold text-2xl">TACE List</div>
        <div className="space-x-4">
          <Link to="/tace_generator/create-tace" state={{ TaceFolderID }}>
            <Button startIcon={<Add />} type="button" variant="contained">
              Create Tace
            </Button>
          </Link>
        </div>
      </Stack>
      <TextField
        value={searchName}
        sx={{
          marginTop: '20px',
        }}
        variant="standard"
        placeholder="Search"
        onChange={(e) => {
          setSearchName(e.target.value)
        }}
      />
      <TableContainer className="mt-10" component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHeadSortable
            onRequestSort={handleRequestSort}
            order={order}
            headCells={headCells}
            orderBy={orderBy}
          />
          <TableBody>
            {files
              .filter((x) =>
                x.name.toLocaleLowerCase().match(searchName.toLocaleLowerCase())
              )
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    className="hover:shadow-md hover:cursor-pointer"
                    onClick={() =>
                      window
                        .open(
                          `https://docs.google.com/spreadsheets/d/${row.id}`,
                          '_blank'
                        )
                        .focus()
                    }
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className="hover:shadow-md hover:cursor-pointer"
                    onClick={() =>
                      window
                        .open(
                          `https://drive.google.com/drive/folders/${row.properties?.reportFolderId}`,
                          '_blank'
                        )
                        .focus()
                    }
                  >
                    {row.properties?.reportFolderId}
                  </TableCell>
                  <TableCell>
                    {moment(row.properties?.startDate).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(row.properties?.endDate).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(row.createdTime).format('DD-MM-YYYY, hh:mm:ss')}
                  </TableCell>
                  <TableCell align="right">
                    <TableAction
                      file={row}
                      handleDelete={() => handleDeleteFolder(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TaceList
