export default function getTotalPerBusinessUnit(data, months) {
  const res = {
    Groupe: {
      amount: 0,
      product: 0,
    },
    Solution: {
      amount: 0,
      product: 0,
    },
    Immersion: {
      amount: 0,
      product: 0,
    },
    Software: {
      amount: 0,
      product: 0,
    },
    'Strategic consulting': {
      amount: 0,
      product: 0,
    },
    'Skills Center': {
      amount: 0,
      product: 0,
    },
  }
  Object.keys(res).forEach((k) => {
    months.forEach((m) => {
      if (!res[k][m.format('MM/YYYY')]) {
        res[k][m.format('MM/YYYY')] = 0
      }
    })
  })
  data.forEach((x) => {
    res[x.businessUnit].amount += x?.amount || 0
    res[x.businessUnit].product += x?.product || 0
    res.Groupe.amount += x.amount
    res.Groupe.product += x.product
    months.forEach((m) => {
      if (!res[x.businessUnit][m.format('MM/YYYY')]) {
        res[x.businessUnit][m.format('MM/YYYY')] = 0
      }
      if (x[m.format('MM/YYYY')]) {
        res[x.businessUnit][m.format('MM/YYYY')] += x[m.format('MM/YYYY')].amount
        res.Groupe[m.format('MM/YYYY')] += x[m.format('MM/YYYY')].amount
      }
    })
  })
  return res
}
