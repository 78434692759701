import React from 'react'

const initialState = {
  linearLoading: false,
  loading: false,
  toastMessage: {
    type: 'info',
    message: '',
  },
  confirmDialog: {
    open: false,
    callback: () => ({}),
    refreshData: () => ({}),
    description: ''
  },
}

export const ACTIONS = {
  toggleLinearLoading: 'TOGGLE_LINEAR_LOADING',
  toggleLoading: 'TOGGLE_LOADING',
  setToastMessage: 'SET_TOAST_MESSAGE',
  setConfirmDialog: 'SET_CONFIRM_DIALOG'
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.toggleLinearLoading:
      return {
        ...state,
        loading: action.payload,
        linearLoading: true,
      }
    case ACTIONS.toggleLoading:
      return {
        ...state,
        loading: action.payload,
        linearLoading: false,
      }
    case ACTIONS.setToastMessage:
      return {
        ...state,
        toastMessage: action.payload,
      }
    case ACTIONS.setConfirmDialog:
      return {
        ...state,
        confirmDialog: action.payload,
      }
    default:
      return state
  }
}

function useUIStore() {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return {
    state,
    dispatch,
  }
}

export default useUIStore
