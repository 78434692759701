/* eslint-disable */
import * as React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import Logo from '@/assets/images/logo.svg'
import layoutConfig from '@/config/layout'
import Color from '@/config/color'

import ClientListIcon from '@/assets/images/client-list.svg'
import EmployeePrice from '@/assets/images/employee-price.svg'
import { ListSubheader, Stack } from '@mui/material'
import { Logout } from '@mui/icons-material'
import useAuth from '@/hooks/auth'
import Cookies from 'universal-cookie'

const openedMixin = (theme) => ({
  width: layoutConfig.drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: Color.primary,
  color: Color.white,
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: layoutConfig.drawerWidth,
    width: `calc(100% - ${layoutConfig.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: layoutConfig.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiListItemButton-root:hover': {
    backgroundColor: Color.blue,
  },
  '& .active': {
    backgroundColor: Color.blue,
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

function MainLayout({ children }) {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const [open] = React.useState(true)
  const location = useLocation()
  const { state, logout } = useAuth()

  const roleName = React.useMemo(() => state.user?.role, [state.user])

  function onLogout() {
    localStorage.clear()

    cookies.remove('email', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('password', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('token', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('accessToken', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('user_role', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('*', { path: '/', domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.set('isSignedOut', true, {
      path: '/',
      domain: process.env.REACT_APP_SUB_DOMAIN,
    })

    logout()
    navigate('/login')
  }
  async function backToIntra() {
    await logout()
    window.location.href = 'https://intra.peppermint.company'
  }
  const menuItems = [
    {
      title: 'Home',
      key: 'home',
      children: [
        {
          title: 'Client list',
          key: 'client',
          path: '/client',
          icon: ClientListIcon,
        },
      ],
    },
    {
      title: 'Profitability',
      key: 'profitability',
      children: [
        {
          title: 'Staff Reports',
          key: 'staff_report_management',
          path: '/staff_report_management',
          icon: ClientListIcon,
        },
        {
          title: 'Leaves & Holidays',
          key: 'leave_holiday',
          path: '/leave_holiday',
          icon: ClientListIcon,
        },
        {
          title: 'TACE Generator',
          key: 'tace_generator',
          path: '/tace_generator',
          roleAllowed: ['ADMIN'],
          icon: ClientListIcon,
        },
        {
          title: 'Revenue Forecast',
          key: 'revenue_forecast',
          path: '/revenue_forecast',
          roleAllowed: ['ADMIN'],
          icon: EmployeePrice,
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      children: [
        {
          title: 'Roles',
          key: 'role',
          path: '/roles',
          icon: EmployeePrice,
        },
        {
          title: 'Resources',
          key: 'resources',
          path: '/resources',
          icon: EmployeePrice,
        },
      ],
    },
  ]

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar elevation={1} color="secondary" position="fixed" open={open}>
        <Stack direction="row" justifyContent="space-between">
          <Toolbar>
            <div className="text-primary font-semibold ml-6">
              Peppermint client generator
            </div>
          </Toolbar>
          <Toolbar>
            <div className="text-primary font-semibold ml-6">{roleName}</div>
          </Toolbar>
        </Stack>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div className="flex justify-center items-center my-8">
          <button onClick={backToIntra}>
            <img src={Logo} alt="logo" />{' '}
          </button>
        </div>
        {menuItems.map((headerMenu) => (
          <List
            key={headerMenu.key}
            subheader={
              <ListSubheader
                sx={{ backgroundColor: Color.primary, color: Color.lightgray }}
              >
                {headerMenu.title}
              </ListSubheader>
            }
          >
            {headerMenu.children
              .filter((menu) => {
                if (menu.roleAllowed) {
                  return menu.roleAllowed.includes(state?.user?.role)
                }
                return true
              })
              .map((menu) => (
                <Link to={menu.path} key={menu.key}>
                  <ListItemButton
                    className={location.pathname.includes(menu.key) && 'active'}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <div className="w-12">
                      <img src={menu.icon} alt="icon" />
                    </div>
                    <ListItemText
                      primary={menu.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              ))}
          </List>
        ))}
        <div className="flex justify-center items-center absolute bottom-0 w-full">
          <ListItemButton
            sx={{
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={onLogout}
          >
            <div className="mr-4">
              <Logout />
            </div>
            <ListItemText primary="Log Out" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div>{children}</div>
      </Box>
    </Box>
  )
}

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default MainLayout
