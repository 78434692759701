import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

import * as PropTypes from 'prop-types'
import { Button, IconButton } from '@mui/material';
import { useAsyncRequest, viewSheet } from '@/utils/helper';
import Folder from '@/components/common/Folder'

function ExportDialog({ onClose, open, file }) {
  const { actionWrapper } = useAsyncRequest()

  async function handleView() {
    await actionWrapper(() => viewSheet(file?.sheetId), { showMessage: true })
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="p-5">
        <DialogTitle>
          Votre fichier a été généré avec succès
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className="flex justify-center p-5 space-x-4">
          <Button onClick={() => handleView()}>
            <Folder name="Revenue Forecast" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

ExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  file: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ExportDialog
