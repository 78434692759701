import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'

function descendingComparator(a, b, orderBy) {
  if (_.get(a, orderBy) < _.get(b, orderBy)) {
    return -1
  }
  if (_.get(a, orderBy) > _.get(b, orderBy)) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function TableHeadSortable({ order, orderBy, onRequestSort, headCell }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableCell
      key={`${headCell.id}`}
      style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
      align={headCell?.alignment || 'left'}
      sortDirection={orderBy === headCell.id ? order : false}
    >
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
      >
        {headCell.label}
      </TableSortLabel>
    </TableCell>
  )
}
TableHeadSortable.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCell: PropTypes.object.isRequired,
}

export default TableHeadSortable
