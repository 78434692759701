import httpClient from '@/utils/http-client'

const resouce = 'script'
const appScriptApi = {
  async generateCosting(data) {
    const res = await httpClient({
      url: `${resouce}/costing`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async generatePlanner(data) {
    const res = await httpClient({
      url: `${resouce}/planner`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async generateRevenueForecast(data) {
    const res = await httpClient({
      url: `${resouce}/revenue`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async getRevenueForecast(id, sheetId) {
    const res = await httpClient({
      url: `${resouce}/revenue/${id}/${sheetId}`,
      method: 'GET',
    })
    return res.data
  },
  async generateTace(data) {
    const res = await httpClient({
      url: `${resouce}/tace`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async generateStaffReport(data) {
    const res = await httpClient({
      url: `${resouce}/staff`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async transferToStaff(id, payload) {
    const res = await httpClient({
      url: `${resouce}/planner/transfer-to-staff/${id}`,
      method: 'POST',
      data: payload
    })
    return res.data
  }
}

export default appScriptApi
