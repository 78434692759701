/* eslint-disable */
import React, { useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import ToastMessage from '@/components/common/ToastMessage'
import { ThemeProvider } from '@emotion/react'
import theme from '@/mui/theme'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'

import UIContext from '@/context/uiContext'
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Error from '@/view/Error'
import useUIStore, { ACTIONS } from './store/uiStore'
import ConfirmDialog from './components/common/ConfirmDialog'
import AuthProvider from './components/guard/AuthProvider'
import ProtectedRoute from './components/guard/ProtectedRoute'
import CreateTace from './view/tace_generator/CreateTace'
import TaceList from './view/tace_generator'
import LeaveDayOff from './view/leaveDayOff/payfit'
import StaffReportManagement from './view/staffReport'
import RevenueReport from './view/RevenueReport'
import { useNavigate } from 'react-router-dom'
import Cookie from 'universal-cookie'

const CreateCosting = React.lazy(() => import('./view/client/CreateCosting'))
const EmployeePrice = React.lazy(() => import('./view/EmployeePrice'))
const Role = React.lazy(() => import('./view/Role'))
const Client = React.lazy(() => import('./view/client/Client'))
const Project = React.lazy(() => import('./view/client/Project'))
const Login = React.lazy(() => import('./view/Login'))

const MainView = () => {
  const navigate = useNavigate()
  const cookies = new Cookie()

  const handleSetCookie = () => {
    if(!cookies.get("isSignedOut")) {
      cookies.set("from", "PF", { domain: process.env.REACT_APP_SUB_DOMAIN })
    }
    handleRedirect()
  };

  const handleRedirect = () => {
    window.location.href = process.env.REACT_APP_INTRA_URL
  }

  useEffect(() => {
    if(!cookies.get("email")) {  
      navigate("/login");
      handleSetCookie()
    }
  }, []);

  const routes = useRoutes([
    {
      path: '',
      element: (
        <ProtectedRoute>
          <Client />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: 'client',
      children: [
        {
          path: ':name/:id',
          element: (
            <ProtectedRoute>
              <Project />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-costing',
          element: (
            <ProtectedRoute>
              <CreateCosting />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'staff_report_management',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <StaffReportManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'leave_holiday',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <LeaveDayOff />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'tace_generator',
      children: [
        {
          path: ':name/:id',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <CreateTace />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-tace',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <CreateTace />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <TaceList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'revenue_forecast',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <RevenueReport />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'resources',
      element: (
        <ProtectedRoute>
          <EmployeePrice />
        </ProtectedRoute>
      ),
    },
    {
      path: 'roles',
      element: (
        <ProtectedRoute>
          <Role />
        </ProtectedRoute>
      ),
    },
    { path: 'error', element: <Error /> },
    { path: 'login', element: <Login /> },
  ])
  return routes
}

function App() {
  const { state, dispatch } = useUIStore()

  const handleClose = () => {
    dispatch({
      type: ACTIONS.toggleLoading,
      payload: true,
    })
  }

  return (
    <Router>
      <UIContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <ToastMessage
          message={state.toastMessage.message}
          type={state.toastMessage.type}
          open={state.toastMessage.message !== ''}
          handleClose={() =>
            dispatch({
              type: ACTIONS.setToastMessage,
              payload: { message: '' },
            })
          }
        />
        <ConfirmDialog
          open={state.confirmDialog.open}
          refreshData={state.confirmDialog.refreshData}
          callback={state.confirmDialog.callback}
          description={state.confirmDialog.description}
        />
        <ThemeProvider theme={theme}>
          <React.Suspense
            fallback={(
              <Backdrop
                sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
                open
                onClick={handleClose}
              >
                <CircularProgress color="primary" />
              </Backdrop>
            )}
          >
            {state.loading
              && (state.linearLoading ? (
                <LinearProgress className="my-4" />
              ) : (
                <Snackbar
                  open={state.loading}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <Alert
                    icon={<CircularProgress color="primary" size="1.5rem" />}
                    severity="info"
                    sx={{ width: '100%' }}
                  >
                    Loading
                  </Alert>
                </Snackbar>
              ))}
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MainView />
              </LocalizationProvider>
            </AuthProvider>
          </React.Suspense>
        </ThemeProvider>
      </UIContext.Provider>
    </Router>
  )
}

export default App
