import React from 'react'
import * as PropTypes from 'prop-types';
import fileImg from '../../assets/images/file.png'

export default function Folder({ name }) {
  return (
    <div
      className={` 
    w-32 h-32 border border-gray-100 rounded shadow-sm
    flex flex-col justify-center items-center hover:w-36 hover:h-36 hover:bg-blue-100
    duration-100 transition-all cursor-pointer`}
    >
      <img className=" h-8 w-8" src={fileImg} alt="file" />
      <div className="mt-3">{name}</div>
    </div>
  )
}

Folder.propTypes = {
  name: PropTypes.string.isRequired,
}
