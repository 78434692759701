import React from 'react'
import PropTypes from 'prop-types'
import useAuth from '@/hooks/auth'
import { Navigate } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'

function ProtectedRoute({ roleAllowed, children }) {
  const { state } = useAuth()

  if (
    (!state.user
    || !state.token)
    || (roleAllowed.length > 0
      && !roleAllowed.includes(state?.user?.role))
  ) {
    return <Navigate to="/login" />
  }
  return <MainLayout>{children}</MainLayout>
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
  roleAllowed: PropTypes.array,
}
ProtectedRoute.defaultProps = {
  roleAllowed: [],
}
export default ProtectedRoute
