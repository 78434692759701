import React from 'react'

import FileManagment from '@/components/common/FileManagement'

function StaffReportManagement() {
  return (
    <FileManagment
      folderId={process.env.REACT_APP_STAFF_REPORT_FOLDER_ID || '1FD_R9zTfzQA9cyyvgnVU4nTYArgNUnbk'}
      folderType="Staff Reports"
    />
  )
}

export default StaffReportManagement
