import { TOKEN_KEY } from '@/config/app';
import axios from 'axios'

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default service
