/* eslint-disable no-unused-vars */
import { Add, ArrowForwardIos } from '@mui/icons-material'
import appScriptApi from '@/api/app-script'
import { useAsyncRequest } from '@/utils/helper'
import efficentSaleApi from '@/api/efficient-sales'

import { Button, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import iterationApi from '@/api/iteration'
import TableHeadSortable, { getComparator } from './components/HeadCell'
import getAmmountPerMonthByRatio from './utils/get_ammount_per_month.ts'
import getTotalPerBusinessUnit from './utils/get_total_per_unit.ts'
import ExportDialog from './components/ExportDialog'
import getDataFromCosting from './utils/get_data_from_costing.ts'

function RevenueReport() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [months, setMonths] = useState([])
  const [opportunities, setOpportunities] = useState([])
  const [businessUnits, setBusinessUnits] = useState({})
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('clientName')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { actionWrapper } = useAsyncRequest()
  const [exportFile, setExportFile] = useState({})
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const headCells = [
    {
      id: 'clientName',
      label: 'Client',
    },
    {
      id: 'comment',
      label: 'Comment',
    },
    {
      id: 'businessUnit',
      label: 'Business Unit',
    },
    {
      id: 'contact',
      label: 'Contact',
    },
    {
      id: 'forecast_level',
      label: 'Forecast Level',
    },
    {
      id: 'project',
      label: 'Project',
    },
    {
      id: 'amount',
      label: 'Contract',
    },
    {
      id: 'product',
      label: 'Produit',
    },
  ]
  function handleCloseDialog() {
    setOpenDownloadDialog(false)
  }
  function resetRangeMonth(start, end) {
    const firstMonth = moment(start).clone().startOf('month')
    const endMonth = moment(end).clone()
    const res = []
    if (!start || !end) {
      return
    }
    while (firstMonth.isBefore(endMonth) || firstMonth.isSame(endMonth, 'month')) {
      res.push(firstMonth.clone());
      firstMonth.add(1, 'month');
    }
    setMonths(res)
  }

  function exportToPdf() {
    const doc = new JsPDF({
      orientation: 'landscape',
    })
    autoTable(doc, { html: '#my-table', tableWidth: 'wrap', horizontalPageBreak: true, useCss: true, })
    doc.save('table.pdf')
  }
  async function exportToGoogleSheet() {
    const start = startDate ? startDate.format('DD/MM/YYYY') : null
    const end = endDate ? endDate.format('DD/MM/YYYY') : null

    const payload = {
      startDate: start,
      endDate: end,
      data: opportunities
    }
    const sheet = await actionWrapper(() => appScriptApi.generateRevenueForecast(payload), {
      showMessage: true,
    })
    if (sheet) {
      setExportFile(sheet)
      setOpenDownloadDialog(true)
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const getOpportunities = async () => {
    const res = await actionWrapper(() => efficentSaleApi.getOpportunity(), {
      showMessage: true,
    })
    const tmp = await Promise.all(res.data.map(async (x) => {
      console.log(x)
      const start = moment(x?.projectStartingDate)
      const end = moment(x?.projectEndDate)
      let opp = {
        // eslint-disable-next-line no-underscore-dangle
        id: x?._id,
        clientName: x.company?.name,
        comment: '',
        businessUnit: x?.businessUnit,
        forecast_level: x?.stateOpportunity,
        contact: x?.owner?.contactName,
        amount: x?.amount,
        product: x?.prodAmount,
        project: x?.name,
        startDate: start,
        endDate: end,
        costingId: x?.costingId,
        fileId: x?.costingFile,
      }
      if (x?.costingId) {
        const costing = await await actionWrapper(() => appScriptApi.getRevenueForecast(
          x?.costingId, x?.costingFile
        ), {
          showMessage: true,
        })
        if (costing) {
          opp = { ...opp, ...costing }
        }
      } else {
        opp = { ...opp, ...getAmmountPerMonthByRatio(x?.prodAmount, start, end) }
      }
      return opp
    }))
    console.log(tmp)
    setOpportunities(tmp)
    const buTotal = getTotalPerBusinessUnit(tmp, months)
    setBusinessUnits(buTotal)
  }
  useEffect(() => {
    getOpportunities()
  }, [])

  return (
    <div className="flex flex-col w-[76vw] space-y-4">
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <div className="text-primary font-bold text-2xl">Revenue Forecast</div>
        <Stack direction="row" spacing={1}>
          <DatePicker
            views={['month', 'year']}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue)
              if (!newValue) {
                return
              }
              resetRangeMonth(newValue, endDate)
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                required
                {...params}
                helperText={null}
              />
            )}
          />
          <ArrowForwardIos />

          <DatePicker
            views={['month', 'year']}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue)
              if (!newValue) {
                return
              }
              resetRangeMonth(startDate, newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                required
                helperText={null}
              />
            )}
          />
        </Stack>
        <div className="space-x-4">

          <Button
            startIcon={<Add />}
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            type="button"
            variant="contained"
          >
            Export to
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={exportToPdf}>PDF</MenuItem>
            <MenuItem onClick={exportToGoogleSheet}>Google Sheet</MenuItem>
          </Menu>
        </div>
      </Stack>
      <TableContainer style={{ overflow: 'auto' }}>
        <Table style={{ width: '100%', textAlign: 'center' }} id="my-table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4} rowSpan={7} />
              <TableCell rowSpan={7} style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                Forecast Brut
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Business Units
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Montant Contrats
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                Produit
              </TableCell>
              {months.map((m) => ((
                <TableCell style={{ fontWeight: 'bold' }} key={m.format('MM/YYYY')}>{m.format('MM/YYYY')}</TableCell>
              )))}
            </TableRow>
            {Object.keys(businessUnits).map((k) => {
              const bu = businessUnits[k]
              return (
                <TableRow key={k}>
                  {k === 'Groupe' ? <TableCell style={{ fontWeight: 'bold' }}>{k}</TableCell> : <TableCell>{k}</TableCell>}
                  <TableCell>
                    €
                    {' '}
                    {bu.amount.toLocaleString('en-US')}
                  </TableCell>
                  <TableCell>
                    €
                    {' '}
                    {bu.product.toLocaleString('en-US')}
                  </TableCell>
                  {months.map((m) => {
                    const mAmount = _.get(bu, `${m.format('MM/YYYY')}`, 0)
                    return (
                      <TableCell key={`${m.format('MM/YYYY')}${k}`}>{mAmount > 0 ? `€ ${mAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : '-'}</TableCell>

                    )
                  })}
                </TableRow>
              )
            })}
            <TableRow>
              {headCells.map((c) => (
                <TableHeadSortable
                  key={c.id}
                  onRequestSort={handleRequestSort}
                  order={order}
                  headCell={c}
                  orderBy={orderBy}
                />
              ))}
              {months.map((m) => ((
                <TableCell style={{ fontWeight: 'bold' }} key={m.format('MM/YYYY')}>{m.format('MM/YYYY')}</TableCell>
              )))}
            </TableRow>
            {opportunities.sort(getComparator(order, orderBy)).map((deal) => (
              <TableRow key={deal.id}>
                <TableCell>{deal.clientName}</TableCell>
                <TableCell>{deal.comment}</TableCell>
                <TableCell>{deal.businessUnit}</TableCell>
                <TableCell>{deal.contact}</TableCell>
                <TableCell>{`${deal.forecast_level}`}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{deal.project}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{`€ ${deal.amount.toLocaleString('en-US')}`}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{`€ ${deal.product.toLocaleString('en-US')}`}</TableCell>
                {months.map((m) => {
                  let mAmount = 0
                  if (typeof m.format('MM/YYYY') === 'object') {
                    mAmount = _.get(deal, `${m.format('MM/YYYY')}.amount`, 0)
                  } else {
                    mAmount = _.get(deal, `${m.format('MM/YYYY')}`, 0)
                  }
                  if (typeof mAmount === 'object') {
                    mAmount = mAmount.amount
                  }
                  return (
                    <TableCell style={{ whiteSpace: 'nowrap' }} key={`${m.format('MM/YYYY')}${deal.id}`}>{mAmount > 0 ? `€ ${mAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : '-'}</TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ExportDialog
        open={openDownloadDialog}
        onClose={handleCloseDialog}
        file={exportFile}
      />
    </div>
  )
}

export default RevenueReport
