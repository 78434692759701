import {
  TextField,
  Box,
  Select,
  MenuItem,
  // FormControl,
  Checkbox,
  Autocomplete,
  Button,
  FormGroup,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import folderApi from '@/api/folder'
import { useAsyncRequest } from '@/utils/helper'
import employeeApi from '@/api/employee'
import UIContext from '@/context/uiContext'
import { DatePicker } from '@mui/x-date-pickers'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import appScriptApi from '@/api/app-script'

function CreateTace() {
  const staffFolderId = process.env.REACT_APP_STAFF_REPORT_FOLDER_ID
    || '1FD_R9zTfzQA9cyyvgnVU4nTYArgNUnbk'
  const [fileName, setFileName] = useState('')
  const [projectFolder, setProjectFolder] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const { actionWrapper } = useAsyncRequest()
  const [folders, setFolders] = useState([])

  const { state: uiState } = React.useContext(UIContext)

  const [employees, setEmployees] = useState([])
  const [employeesSelected, setEmployeesSelected] = useState([])

  async function getEmployees() {
    const employeesRes = await employeeApi.get()
    const sortedData = employeesRes.data.sort(
      (a, b) => -b.name.localeCompare(a.name)
    )
    setEmployeesSelected(sortedData)
    setEmployees(sortedData)
  }

  async function fetchFolders() {
    const res = await actionWrapper(() =>
      folderApi.listFoldersById(staffFolderId)
    )
    setFolders(res.files)
  }

  useEffect(() => {
    getEmployees()
    fetchFolders()
  }, [])

  async function submitForm(event) {
    event.preventDefault()
    const payload = {
      fileName,
      startDate: startDate.format(),
      endDate: endDate.format(),
      folderId:
        process.env.REACT_APP_TACE_FOLDER_ID
        || '1feUEwZUbwt9bmF_Bky4OoGymjjLV-Svr',
      reportFolderId: projectFolder,
      plannerFolderId: '1GeJeX1sfVcKtqiqhj9p9EWpJ_zwL7y12',
      resources: employeesSelected.map(({ id, name, businessUnit, isFreelance, role }) => ({
        id,
        name,
        businessUnit,
        isFreelance,
        role,
      })),
    }
    const res = await actionWrapper(() => appScriptApi.generateTace(payload), {
      showMessage: true,
    })
    console.log(res)
  }

  return (
    <Box
      component="form"
      className="space-y-6 text-primary"
      onSubmit={submitForm}
    >
      <div className="flex justify-between">
        <div className="flex text-primary font-bold text-3xl">
          Create a TACE
        </div>
      </div>

      {/* Info */}
      <Box variant="outlined" className="p-4 bg-lightgray rounded h-full">
        <div className="flex text-primary font-semibold text-3xl mb-4">
          Info
        </div>
        <FormGroup>
          <div className="grid grid-cols-3 gap-6 mb-4">
            <div className="flex flex-col">
              <div className="font-light flex items-center mb-2">Filename:</div>
              <TextField
                size="small"
                fullWidth
                disabled={uiState.loading}
                className="bg-white"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>
            <div className="flex flex-col">
              <div className="font-light flex items-center mb-2">Folder:</div>
              {/* <FormControl required className="bg-white space-y-3"> */}
              <Select
                size="small"
                disabled={uiState.loading}
                value={projectFolder}
                onChange={(event) => setProjectFolder(event.target.value)}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <span className="text-darkgray">Staff Report Folder</span>
                </MenuItem>
                {folders.length > 0
                    && folders.map((folder) => (
                      <MenuItem key={folder.id} value={folder.id}>
                        {folder.name}
                      </MenuItem>
                    ))}
              </Select>
              {/* </FormControl> */}
            </div>
            <div className="flex flex-col" />
            <div className="flex flex-col">
              <div className="font-light flex items-center mb-2">Start date:</div>
              <DatePicker
                views={['day']}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={null} />
                )}
              />
            </div>
            <div className="flex flex-col">
              <div className="font-light flex items-center mb-2">End date:</div>
              <DatePicker
                views={['day']}
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue)
                }}
                renderInput={(params) => (
                  <TextField {...params} required helperText={null} />
                )}
              />
            </div>
            <div className="flex flex-col" />

            <div className=" flex flex-col  col-span-3">
              <div className="font-light flex items-center mb-2">Resources:</div>
              <Autocomplete
                multiple
                limitTags={3}
                id="checkboxes-tags-demo"
                options={employees}
                value={employeesSelected}
                onChange={(e, val) => setEmployeesSelected(val)}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={`${option.id}-select-resource-tace`}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </FormGroup>

      </Box>
      <div className="absolute bottom-5 right-5">
        <Button variant="contained" type="submit">
          Create TACE
        </Button>
      </div>
    </Box>
  )
}
export default CreateTace
