import httpClient from '@/utils/http-client'

const resouce = 'iteration-payloads'
const iterationApi = {
  async create(data) {
    const res = await httpClient({
      url: `${resouce}`,
      method: 'POST',
      data,
    })
    return res.data
  },

  async getById(id) {
    const res = await httpClient({
      url: `${resouce}/iteration/${id}`,
      method: 'GET',
    })
    return res.data
  },

  async deleteById(id) {
    const res = await httpClient({
      url: `${resouce}/iteration/${id}`,
      method: 'DELETE',
    })
    return res.data
  },
}

export default iterationApi
