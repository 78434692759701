export default function getAmmountPerMonthByRatio(amount, startMoment, endMoment) {
  // Convert the start and end dates to Moment.js objects
  const startYear = startMoment.year();
  const startMonth = startMoment.month();
  const endYear = endMoment.year();
  const endMonth = endMoment.month();
  const numMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

  const distribution = {}

  for (let i = 0; i < numMonths; i += 1) {
    const currentMonth = startMoment.clone().add(i, 'months')
    const isStartMonth = i === 0
    const isEndMonth = i === numMonths - 1

    let daysInMonth
    if (isStartMonth && isEndMonth) {
      daysInMonth = endMoment.diff(startMoment, 'days') + 1
    } else if (isStartMonth) {
      daysInMonth = currentMonth.endOf('month').diff(startMoment, 'days') + 1
    } else if (isEndMonth) {
      daysInMonth = endMoment.diff(currentMonth.startOf('month'), 'days') + 1
    } else {
      daysInMonth = currentMonth.daysInMonth()
    }
    const duration = endMoment.diff(startMoment, 'days') + 1
    const ratio = daysInMonth / duration
    const monthlyAmount = amount * ratio
    distribution[currentMonth.format('MM/YYYY')] = {
      month: currentMonth.format('MM/YYYY'),
      numberOfDays: daysInMonth,
      amount: monthlyAmount, // Round to 2 decimal places
    }
  }
  return distribution
}
