import httpClient from '@/utils/http-client'

const resouce = 'employees'
const employeeApi = {
  async get(params) {
    const res = await httpClient({
      url: `${resouce}`,
      method: 'GET',
      params,
    })
    return res.data
  },
  async getAllocation(params) {
    const res = await httpClient({
      url: `${resouce}/allocation`,
      method: 'GET',
      params,
    })
    return res.data
  },

  async create(data) {
    const res = await httpClient({
      url: `${resouce}`,
      method: 'POST',
      data,
    })
    return res.data
  },

  async udpateById(id, data) {
    const res = await httpClient({
      url: `${resouce}/${id}`,
      method: 'PUT',
      data,
    })
    return res.data
  },

  async deleteById(id) {
    const res = await httpClient({
      url: `${resouce}/${id}`,
      method: 'DElETE',
    })
    return res.data
  },
}

export default employeeApi
